﻿/*
--------------------------------------------------------------------------------
Login popup
--------------------------------------------------------------------------------

<![CDATA[

*/
ori.popup.addModule("popupVideoyouku", {
    videoRatio: 720 / 1280,
    videoPlaceholderWidth: 1280,
    videoPlaceholderHeight: 720,
    ykContainerSelector: "#youku-player-container",
    videoContainerId: "youkuplayer",
    videoSourceWrapperSelector: "#video-popup-container",
    ykSelector: "a.js-ykplayer",
    openpopup: false,
    recordInListVideoIds: "div[id=\"[video_id]\"]",
    notInited: ":not([data-video-inited])",
    // common used selectors
    sel: {
        defaultSelector: ".popup-video",
        popup: {
            CONTAINER: ".k-window",
            CONTENT: ".k-window-content"
        }
    },
    //Attributes
    attrs: {
        OPENPOPUP: "data-openpopup",
        INITED: "data-video-inited"
    },
    html: {
        RECORD_IN_LIST_VIDEO_IDS: "<div id=\"[video_id]\"></div>"
    },
    //All other settings
    other: {
        AUTO_PLAY_HASH_MARK: "play-video",
        DATA_STORAGE: "ori.popup.video.storage"
    },
    // default popup options stored here
    // can be overwritten in init()
    kendoOptions: {
        title: false,
        width: 854,
        height: 480,
        iframe: true,
        open: function () {
            ori.spinner.applyOverlayTo(ori.popup.popupVideoyouku.sel.popup.CONTAINER);  // add overlay with default spinner
        },
        activate: function () {
            ori.spinner.removeOverlayFrom(ori.popup.popupVideoyouku.sel.popup.CONTAINER);  // remove overlay
            ori.popup.popupVideoyouku.resizeVideoPopup();
        },
        close: function () {
            $(ori.popup.popupVideoyouku.ykContainerSelector + " iframe").remove(); //remove content and make new container div when the lightbox closes
            $(ori.popup.popupVideoyouku.ykContainerSelector).append($("<div />").attr("id", ori.popup.popupVideoyouku.videoContainerId));
        }
    },

    // Initialization of default lightbox options.
    init: function (options) {
        var self = this;

        self.setOptions(options);
        //Correct attribute "open as popup" (settings.attr.OPENPOPUP)
        self.checkAttributes();

        if (self.options.ykSelector !== undefined) {
            self.ykSelector = self.options.ykSelector;
        }

        if ($(self.ykSelector).data("openpopup") !== "undefined") {
            self.sel.openpopup = $(self.ykSelector).data("openpopup");
        }

        if (self.sel.openpopup) {
            var $videoPlaceholder = $(self.ykSelector),
                videoAtributeId = $videoPlaceholder.data("videoid"),
                playerUrl = "//player.youku.com/embed/" + videoAtributeId,
                url = playerUrl + "?ver=3&rel=0&border=0&showsearch=0&autoplay=1&showinfo=0&enablejsapi=1&playerapiid=player1"; // &color2=0

            self.placeholders();
            self.container();

            if (typeof videoAtributeId !== "undefined") {
                self.videoContainerId = videoAtributeId;
            }

            self.kendoOptions.width = self.videoPlaceholderWidth;
            self.kendoOptions.height = self.videoPlaceholderHeight;
            self.kendoOptions.content = url;

            $(self.videoSourceWrapperSelector).append($(self.ykContainerSelector));

            var o = jQuery.extend(
                {},
                self.kendoOptions,
                self.options
            );

            $(self.ykSelector).on(self.eventString("click"),
                function (event) {
                    var popup = $(self.ykContainerSelector);
                    event.preventDefault();

                    if (popup.length > 0) {
                        popup.kendoWindow(o);
                        var kendoPopupWindow = popup.data("kendoWindow");
                        kendoPopupWindow.open().center();
                    }
                });

            if (location.hash.search(self.other.AUTO_PLAY_HASH_MARK) > -1) {
                $(self.ykSelector).click();
                location.hash = location.hash.replace(self.other.AUTO_PLAY_HASH_MARK, "");
            }

            if (!ori.device) {
                self.error("Couldn't find device module, layout updates may not work properly.");
                return;
            } else {
                var handler = function () {
                    self.resizeVideoPopup();
                };
                ori.device.bindResize(handler, self.ns);
                ori.device.bindMedia(handler, self.ns);
            };
        }
    },

    resizeVideoPopup: function () {
        var self = this,
            iFrame = $(self.ykContainerSelector).find("iframe"),
            box = iFrame.closest($(self.sel.popup.CONTENT)),
            width = null,
            height = null;
        if (iFrame.length === 1) {
            //Get real size
            width = $(box).width();
            height = Math.ceil(width * self.videoRatio);
            //Save size to storage
            iFrame[0][self.other.DATA_STORAGE] = {
                width: width,
                height: height,
            };
            //Change size of popup
            var popupHeight = height + ($(box).outerHeight(true) - $(box).height());
            var kendoPopupWindow = box.data("kendoWindow");
            kendoPopupWindow.setOptions({
                width: (width > self.videoPlaceholderWidth) ? width : self.videoPlaceholderWidth,
                height: popupHeight
            });
            //Change size of iframe
            $(iFrame).width(width);
            $(iFrame).height(height);
            iFrame[0].width = width;
            iFrame[0].height = height;
            //Change size of iframe container
            $(iFrame[0].parentNode).width(width);
            $(iFrame[0].parentNode).height(height);
        }
    },
    checkAttributes: function () {
        //Get all video's buttons (<a>, which has information about video (links, ids and etc.)
        var self = ori.popup.popupVideoyouku,
            $placeholders = $(self.ykSelector);
        $placeholders.each(function () {
            var $placeholder = $(this),
                openAsPopup = $placeholder.attr(self.attrs.OPENPOPUP);
            if (openAsPopup !== null && openAsPopup !== "") {
                //Good, button has attribute, but we have to check value of it
                if (["true", "false"].indexOf(openAsPopup) === -1) {
                    $placeholder.attr(self.attrs.OPENPOPUP, openAsPopup);
                }
            } else {
                //If button hasn't attribute "open as popup", add it with default value
                $placeholder.attr(self.attrs.OPENPOPUP, self.openpopup);
            }
        });
    },

    placeholders: function () {
        //Get all video's buttons (<a>, which has information about video (links, ids and etc.)
        var self = ori.popup.popupVideoyouku,
            $placeholders = $(self.ykSelector + self.notInited);
        /**
            * Check each button. Logic:
            * On page should be container with list of all videos:
            <div class="hidden">
                <div id="video-popup-container">
                    <div id="youku-player-container">
                        ===>This is list of videos with IDs<====
                        <div id="ncWhD0v7LVY"></div>
                        <div id="NBHJuJ_TeWc"></div>
                        ===>This is list of videos with IDs<====
                    </div>
                </div>
            </div>
            * Each video in list should have some button (<a>) with same ID and information about video
            * So, we check each button, and if we cannot find some video in list, we add it into list
            */
        $placeholders.each(function () {
            var id = $(this).attr("data-videoid"),
                record = null;
            if (id !== null && id !== "") {
                record = $(self.recordInListVideoIds.replace("[video_id]", id));
                if (record.length === 0) {
                    //If record for some video is missed - add it
                    $(self.ykContainerSelector).append(self.html.RECORD_IN_LIST_VIDEO_IDS.replace("[video_id]", id));
                }
            } else {
                //Something wrong, if button has not ID of video
                self.info("[popup.videoyouku]:: Button with video information has not ID of video.");
            }
            $(record).attr(self.attrs.INITED, "true");
        });
    },

    container: function () {
        var self = ori.popup.popupVideoyouku,
            container = $(self.ykContainerSelector + self.notInited),
            popup = $(self.videoSourceWrapperSelector);
        if (container.length > 0) { // && popup.length > 0
            //Set size of container
            $(container).css({
                'height': self.videoPlaceholderHeight,
                'width': self.videoPlaceholderWidth
            });
            $(popup).append($(container));
            $(container).attr(self.attrs.INITED, "true");
        }
    }
});

/* ]]> */